<template>
  <div class="loading-section">
    <div v-if="loadingState !== ''">
      <div v-if="loadingState == null">
        no data
      </div>
      <slot v-else></slot>
    </div>
    <div v-else class="loading-text">
      <LoadingSpinnerIcon
          :width="50"
      />
    </div>
  </div>
</template>
<script>
import LoadingSpinnerIcon from "@/components/loader/LoadingSpinnerIcon";

export default {
  name: "LoadingSection",
  props: {
    loadingState: {
      default: ""
    }
  },
  components: {
    LoadingSpinnerIcon
  }
};
</script>
<style lang="scss" scoped>
.loading-text {
  font-size: 16px;
}
</style>
